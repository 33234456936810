import { Grid, TextField, InputAdornment, CircularProgress, IconButton, Chip, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import MovieSuggestion from "../components/MovieSuggestion";
import axios from "axios";
import { Clear, Info } from "@mui/icons-material";
import StatsModal from "./StatsModal";
import { BACKEND_URL } from "../utils/constants";

function SearchScreen({previousSearch, onClick=()=>{}}) {
  const [ search, setSearch ] = useState(previousSearch);
  const [ suggestions, setSuggestions ] = useState([]);
  const [debouncedValue] = useDebounce(search, 1000);
  const [ debouncedSearch, setDebouncedSearch ] = useState(previousSearch);
  const [ isLoadingSuggestions, setIsLoadingSuggestions ] = useState(false);
  const [ showStatsModal, setShowStatsModal ] = useState(false);

  const processSearch = (searchData) => {
    setSuggestions((searchData.d).filter(item => item.id.startsWith('tt')));
  }

  const handleSearch = (search) => {
    if(!search) {
      return "";
    }
    const params = {
      search
    }
    const searchUrl = BACKEND_URL + "search.php";

    axios
      .post(searchUrl, params)
      .then((response) => {
        processSearch(response.data)
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    setIsLoadingSuggestions(true);
  };

  useEffect(() => {
    if(debouncedValue !== null || debouncedValue !== debouncedSearch) {
      setDebouncedSearch(debouncedValue);
      handleSearch(search);
      setIsLoadingSuggestions(false);
    }
  }, [debouncedValue]);

  const handleShowStatsModal = () => {
    setShowStatsModal(true);
  };
  const handleHideStatsModal = () => {
    setShowStatsModal(false);
  };

  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: '100vh'
      }}>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4} sx={{ mb: 2 }}>
            <img
              src='imgs/popcorn.svg'
              style={{
                maxHeight: '75px',
                display: 'block',
                margin: '0rem auto 1rem auto'
              }}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Search movie or TV series..."
              variant="outlined"
              value={search}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoadingSuggestions ? <CircularProgress /> : ''}
                    {!isLoadingSuggestions && search ? <IconButton onClick={() => {
                      setSearch("");
                      setDebouncedSearch("");
                    }}>
                      <Clear />
                    </IconButton> : ''}
                  </InputAdornment>
                )
              }}
            />
            <Box sx={{ textAlign: 'right' }}>
              <Chip
                label={
                  <Info style={{ width: '1rem', height: '1rem', marginTop: '6px' }} />
                }
                size="small"
                sx={{
                  my: 0.5
                }}
                variant="outlined"
                onClick={handleShowStatsModal}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={4}>
          {suggestions.length > 0 && suggestions.slice(0, 6).map((movie, index) => {
            return (
              <MovieSuggestion key={index} movie={movie} onClick={() => onClick(movie, debouncedSearch)} />
            );
          })}
        </Grid>
      </div>
      {<StatsModal open={showStatsModal} onClickClose={() => handleHideStatsModal()}/>}
    </>
  );
}

export default SearchScreen;