import './App.css';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import { useState } from 'react';
import Router from './components/Router';
import { Container } from '@material-ui/core';

function App() {
  const [themeType] = useState(true);
  const darkTheme = createTheme({
    palette: {
      mode: themeType ? 'dark' : 'light',
      primary: {
        main: '#F9F6EE'
      },
      secondary: {
        main: "#c23616"
      }
    },
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: '#121212'
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: '#121212'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: '#121212'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '4px'
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth='xl'>
        <Router />
      </Container>
    </ThemeProvider>
  );
}

export default App;
