import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import { BACKEND_URL } from '../utils/constants';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';


export default function StatsModal({open, onClickClose=()=>{}}) {

  const [ latest, setLatest ] = useState([]);
  const [ most, setMost ] = useState([]);
  const [ isExploding, setIsExploding ] = useState(false);

  const handleGetStats = () => {
    const searchUrl = BACKEND_URL + "stats.php";
    axios
      .post(searchUrl)
      .then((response) => {
        setLatest(response.data.latest);
        setMost(response.data.most);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  useEffect(() => {
    handleGetStats();
  }, []);

  return (
    <>
      <Dialog open={open} keepMounted onClose={onClickClose} maxWidth='lg' fullWidth>
        <DialogTitle>Info & Statistics</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p:2 }}>
                  <Typography variant='body1'>
                    Website uses data from&nbsp;
                    <Link color='secondary' underline='hover' href='https://csfd.cz' target='_blank' rel='noreferrer'>csfd.cz</Link>,&nbsp;
                    <Link color='secondary' underline='hover' href='https://imdb.com' target='_blank' rel='noreferrer'>imdb.com</Link> and&nbsp;
                    <Link color='secondary' underline='hover' href='https://rottentomatoes.com' target='_blank' rel='noreferrer'>rottentomatoes.com</Link>.
                  </Typography>
                  <Typography variant='body1'>
                    Website is created for information and non-commercial purposes.
                  </Typography>
                  <Typography variant='body1'>
                    Current version:&nbsp;
                    <Chip
                      size='small'
                      label="v0.5"
                      color="secondary"
                      onClick={() => setIsExploding(!isExploding)}
                      sx={{
                        cursor: 'default',
                        '&:hover':{
                          bgcolor: (theme) => theme.palette.secondary.main
                        }
                      }}
                    />
                    {isExploding &&
                      <ConfettiExplosion
                        force={0.8}
                        duration={3000}
                        particleCount={250}
                        width={1800}
                        zIndex={99999}
                        onComplete={()=> {
                          setIsExploding(false);
                        }}
                      />
                    }
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Paper elevation={2} sx={{ p:2 }}>
                  <Typography variant='h6' gutterBottom>
                    Last searched movies
                  </Typography>
                  <List dense sx={{ width: '100%' }}>
                    {latest.map((movie, index) => {
                      return (
                        <ListItem key={index} sx={{ px: 0 }}>
                          <ListItemAvatar>
                            <Avatar alt={movie.name} src={'imgs/popcorn.svg'}/>
                          </ListItemAvatar>
                          <ListItemText id={index} primary={movie.name} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Paper elevation={2} sx={{ p:2 }}>
                  <Typography variant='h6' gutterBottom>
                    The most searched movies
                  </Typography>
                  <List dense sx={{ width: '100%' }}>
                    {most.map((movie, index) => {
                      return (
                        <ListItem key={index} sx={{ px: 0 }}>
                          <ListItemAvatar>
                            <Avatar alt={movie.name} src={'imgs/popcorn.svg'}/>
                          </ListItemAvatar>
                          <ListItemText id={index} primary={movie.name} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onClickClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}