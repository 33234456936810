import { useEffect, useState } from "react";
import SearchScreen from "../components/SearchScreen";
import { isObjectEmpty } from "../services/utils";
import MovieRatings from "../components/MovieRatings";
import { getRatings } from "../services/service";
import { Fade } from "@material-ui/core";
import { Alert } from "@mui/material";

function Movie() {
  const [ selectedMovie, setSelectedMovie ] = useState({});
  const [ isSearchHidden, setIsSearchHidden ] = useState(false);
  const [ movieRatings, setMovieRatings ] = useState({});
  const [ searchedString, setSearchedString ] = useState("");
  const [ alert, setAlert ] = useState(false);
  const [ alertCollapse, setAlertCollapse ] = useState(false);
  const [ alertMessage, setAlertMessage ] = useState("");

  const handleSelectMovie = (movie, search) => {
    setSearchedString(search);
    if(!movie) {
      return "";
    }
    setSelectedMovie(movie);
    setMovieRatings({});
  };

  const handleClickBack = () => {
    setIsSearchHidden(false);
  };

  useEffect(() => {
    isObjectEmpty(selectedMovie) ? setIsSearchHidden(false) : setIsSearchHidden(true);
  }, [selectedMovie]);

  useEffect(() => {
    const fetchRatings = async (selectedMovie) => {
      setAlert(false);
      setAlertCollapse(false);
      setAlertMessage("");
      try {
        const data = await getRatings(selectedMovie);
        setMovieRatings(data);
      } catch(error) {
        console.error(error);
        setAlert(true);
        setAlertCollapse(true);
        setAlertMessage(error.message);
      }
    };

    if(isSearchHidden && !isObjectEmpty(selectedMovie)) {
      fetchRatings(selectedMovie);
    }
  }, [isSearchHidden]);

  return (
    <>
      {isSearchHidden ? (
        <MovieRatings movie={selectedMovie} ratings={movieRatings} onClickBack={() => handleClickBack()} />
      ) : (
        <SearchScreen previousSearch={searchedString} onClick={(movie, search) => handleSelectMovie(movie, search)}/>
      )}
      {alert &&
        <Fade in={alertCollapse}>
          <Alert variant="filled" severity="error" sx={{ mb: 2, position: 'fixed', zIndex: '9999' }}>
            {alertMessage}
          </Alert>
        </Fade>
      }
    </>
  );
}

export default Movie;