import axios from "axios";
import { BACKEND_URL } from "../utils/constants";

export const getRatings = async (movie) => {
  const params = {
    search: movie.l + " " + movie.y,
    type: movie.q,
    code: movie.id,
    name: movie.l,
    poster: movie.i && movie.i[0] ? movie.i[0] : 'imgs/placeholder-poster.png',
  }
  try {
    const response = await axios.post(BACKEND_URL + 'movie.php', params);
    const data = response.data;
    return data;
  } catch(error) {
    console.error('Error fetching data:', error.message);
  }
}