import { ArrowBackIosNew, Launch } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardContent, CardMedia, Fade, Grid, Paper, Typography, styled } from "@mui/material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { useEffect, useState } from "react";
import { isObjectEmpty } from "../services/utils";

const MovieBackground = styled('div')(({ movieRatings, movie }) => ({
  backgroundColor: movieRatings.color ? '#' + movieRatings.color : 'transparent',
  backgroundImage: 'url(' + (movie.i && movie.i[0] ? movie.i[0] : '') + ')',
  backgroundSize: 'auto 170%',
  backgroundPosition: '107% 50%',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  minHeight: '100vh',
  height: 'auto',
  position: 'fixed',
  filter: 'blur(5px)',
  transform: 'scale(1.05)',
  zIndex: 1,
  transition: '0.2s all',
  '&::before': {
    content: `''`,
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
}));

const SquarePaper = styled(Paper)({
  //aspectRatio: '1 / 1', // commented bcs. rotten tomatoes disabled
  padding: theme => theme.spacing(2),
});

const CsfdCircular = styled(CircularProgressWithLabel)({
  color: '#b90204',
});

const ImdbCircular = styled(CircularProgressWithLabel)({
  color: '#e6b91e',
});

const MetaCircular = styled(CircularProgressWithLabel)({
  color: '#fff',
});

/*
const RtCircular = styled(CircularProgressWithLabel)({
  color: '#f83107',
});
*/

const logoStyle = {
  maxHeight: '40px',
  margin: '1rem auto',
  display: 'block'
}

const launchIconStyle = {
  position: 'absolute',
  bottom: '0.5rem',
  right: '0.5rem',
  color: 'rgba(255,255,255,0.15)',
  transition: '0.2s all',
  '&:hover': {
    color: 'rgba(255,255,255,0.5)',
    transition: '0.2s all'
  }
}

function MovieRatings({movie, ratings={}, onClickBack=()=>{}}) {
  const [ loadingCircular, setLoadingCircular ] = useState("indeterminate");
  const [ movieRatings, setMovieRatings ] = useState({});
  const [ alert, setAlert ] = useState(false);
  const [ alertCollapse, setAlertCollapse ] = useState(false);

  useEffect(() => {
    setAlert(false);
    setAlertCollapse(false);
    setMovieRatings(ratings);
    if(!isObjectEmpty(ratings)) {
      setLoadingCircular("determinate");
    }
    if(ratings.status === 500) {
      setAlert(true);
      setAlertCollapse(true);
    }
  }, [ratings]);

  return (
    <div>
      <MovieBackground movieRatings={movieRatings} movie={movie} />
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh', position: 'relative', zIndex: 2 }}>
        <Grid item xs={11} sm={11} md={10} lg={8} xl={7} sx={{ py: 2 }}>
          <Card
            sx={{
              display: {
                md: 'flex'
              }
            }}
          >
            <CardMedia
              component="img"
              sx={{
                maxWidth: {
                  md: '50%',
                },
                maxHeight: {
                  md: '100%',
                  sm: '300px',
                  xs: '250px',
                },
                objectFit: {
                  md: 'cover',
                  sm: 'contain',
                  xs: 'contain'
                },
                p: {
                  md: 0,
                  sm: 2,
                  xs: 2
                }
              }}
              image={movie.i && movie.i[0] ? movie.i[0] : 'imgs/placeholder-poster.png'}
              alt={movie.l}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pt: 2 }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="p" textAlign='center' variant="h4" gutterBottom={true}>
                  {movie.l}
                </Typography>
                <Typography component="div" color="text.secondary" textAlign='center' variant="h5" sx={{ pb: 2 }}>
                  {movie.y}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <a href={movieRatings.csfdurl} target="_blank" rel="noreferrer" style={{ pointerEvents: !movieRatings.csfdscore ? 'none' : '' }}>
                      <SquarePaper sx={{ p:2, position: 'relative' }} elevation={2}>
                        <img style={logoStyle} src="imgs/csfd.png" />
                        <CsfdCircular variant={loadingCircular.toString()} value={movieRatings.csfdscore} />
                        {movieRatings.csfdurl && <Launch sx={launchIconStyle}/>}
                      </SquarePaper>
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <a href={movieRatings.imdburl} target="_blank" rel="noreferrer" style={{ pointerEvents: !movieRatings.imdbscore ? 'none' : '' }}>
                      <SquarePaper sx={{ p:2, position: 'relative' }} elevation={2}>
                        <img style={logoStyle} src="imgs/imdb.png" />
                        <ImdbCircular variant={loadingCircular.toString()} value={movieRatings.imdbscore} />
                        {movieRatings.imdburl && <Launch sx={launchIconStyle}/>}
                      </SquarePaper>
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <SquarePaper sx={{ p:2, position: 'relative' }} elevation={2}>
                      <img style={logoStyle} src="imgs/meta.png" />
                      <MetaCircular variant={loadingCircular.toString()} value={movieRatings.metascore} />
                    </SquarePaper>
                  </Grid>
                  {/*
                  <Grid item xs={6}>
                    <a href={movieRatings.rturl} target="_blank" rel="noreferrer" style={{ pointerEvents: !movieRatings.rturl ? 'none' : '' }}>
                      <SquarePaper sx={{ p:2, position: 'relative' }} elevation={2}>
                        <img style={logoStyle} src="imgs/rt.png" />
                        <RtCircular variant={loadingCircular.toString()} value={movieRatings.rtscore} />
                        {movieRatings.rturl && <Launch sx={launchIconStyle}/>}
                      </SquarePaper>
                    </a>
                  </Grid>
                  */}
                </Grid>
              </CardContent>
              <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'start', p: 2 }}>
                {alert &&
                  <Fade in={alertCollapse}>
                    <Alert variant="filled" severity="error" sx={{ mb: 2, width: '100%' }}>
                      {ratings.error_message}
                    </Alert>
                  </Fade>
                }
                <Button variant="outlined" onClick={() => onClickBack()} startIcon={<ArrowBackIosNew />}>Back</Button>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
export default MovieRatings;