import { darken, lighten } from '@mui/material/styles';

const backgroundConstant = 0.8; // 0.7
const hoverBackgroundConstant = 0.6; // 0.6
const selectedBackgroundColor = 0.5; // 0.5
const selectedHoverBackgroundColor = 0.4; // 0.4

export const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, backgroundConstant) : lighten(color, backgroundConstant);

export const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, hoverBackgroundConstant) : lighten(color, hoverBackgroundConstant);

export const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, selectedBackgroundColor) : lighten(color, selectedBackgroundColor);

export const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, selectedHoverBackgroundColor) : lighten(color, selectedHoverBackgroundColor);

export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0;
}